import { guid } from "@/utils/other.js";
import { toolsts } from "@/api";
//上传阿里云
export async function uploadRequest(file) {
  let that = this;
  console.log(document.cookie.split("=")[1], "本地");
  const self = this;
  let type = file.type;
  let size = file.size;
  let pos = file.name.lastIndexOf(".");
  let filename = file.name.substring(0, pos);
  let extendName = file.name.substring(pos + 1);
  // let date = new Date().getTime();
  let fileNames = `${guid()}.${extendName}`; // 拼接文件名，保证唯一，这里使用时间戳+原文件名

  // await this.getOss();
  let client;
  let resUrl = toolsts().then((res) => {
    if (res.code === 200) {
      const {
        accessKeyId,
        accessKeySecret,
        stsToken,
        bucket,
        region,
        endpoint,
      } = res.data;
      const OSS = require("ali-oss");
      client = new OSS({
        endpoint: endpoint,
        accessKeyId: accessKeyId,
        accessKeySecret: accessKeySecret,
        stsToken: stsToken,
        bucket: bucket,
        // secure: true,
        region: region,
      });
      const options = {
        meta: { temp: "demo" },
        mime: "json",
        headers: {
          "Content-Type": "text/plain",
          // "Authorization": document.cookie ? document.cookie.split('=')[1] : ''
        },
      };
      const rest = client
        .put("headerimages/" + fileNames, file, options)
        .then((result) => {
          if (result.res.statusCode == 200) {
            // 上传之后的文件地址
            let uploadedUrl = result.res.requestUrls[0];
            if (size >= 100 * 1024) {
              uploadedUrl = result.res.requestUrls[0].split("?")[0];
            } else {
              uploadedUrl = result.res.requestUrls[0];
            }
            return uploadedUrl;
            // console.log(result, "resultresultresult");
          }
          // this.realMaxFileCount += 1
        })
        .catch((err) => {
          this.$message.error("阿里云上传失败");
        });

      return rest;
    } else {
      this.$message.error(res.msg);
    }
  });
  return resUrl;
}
